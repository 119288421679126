import moment from 'moment';
import config from '../config';
import Request from '../helpers/request';
import { getQueryParams } from '../utils';
import { ExchangeRate, StatusDto, WithSecurity } from '../typings';
import {
  CardDto,
  CardRequestDto,
  CardTransactionDto,
  CardTransactionListDto,
  CardTransactionStatus,
  CardTransactionType,
} from '../typings/cards';
import { CardsListDto } from '../typings/cards';
import { CardSupportedCurrencyListDto } from '../typings/cards';

export const getCards = async (data: {
  page: number;
  size: number;
  id?: string;
  status?: string;
  scheme?: string;
  currency?: string;
  firstName?: string;
  lastName?: string;
  balanceFrom?: number;
  balanceTo?: number;
  reference?: string;
  creationDateFrom?: Date;
  creationDateTo?: Date;
}): Promise<CardsListDto> => {
  const {
    page = 1,
    size = 20,
    id,
    status,
    scheme,
    currency,
    firstName,
    lastName,
    balanceFrom,
    balanceTo,
    reference,
    creationDateFrom,
    creationDateTo,
  } = data;

  return await Request.getWithUserToken<CardsListDto>(
    `${config.api.cards}${getQueryParams({
      id,
      currency,
      page,
      size,
      status,
      scheme,
      firstName,
      lastName,
      balanceFrom,
      balanceTo,
      reference,
      creationDateFrom: creationDateFrom ? moment(creationDateFrom, 'ddd MMM D Y HH:mm:s').format('yyyy-MM-DD') : undefined,
      creationDateTo: creationDateTo ? moment(creationDateTo, 'ddd MMM D Y HH:mm:s').format('yyyy-MM-DD') : undefined,
    })}`,
  );
};

export const createCard = async (data: WithSecurity<CardRequestDto>): Promise<CardDto> => {
  return await Request.postWithUserToken<CardDto>(`${config.api.cards}`, { data });
};

export const getCardById = async (id: string): Promise<CardDto> => {
  return await Request.getWithUserToken<CardDto>(`${config.api.cards}/${id}`);
};

export const fundCard = async (id: string, amount: number, currency: string, security: string): Promise<CardTransactionDto> => {
  return await Request.postWithUserToken<CardTransactionDto>(`${config.api.cards}/${id}/fund`, {
    data: {
      amount,
      currency,
      security,
    },
  });
};

export const withdrawCardFunds = async (id: string, amount: number, currency: string, security: string) => {
  return await Request.postWithUserToken<CardTransactionDto>(`${config.api.cards}/${id}/withdraw`, {
    data: {
      amount,
      currency,
      security,
    },
  });
};

export const freezeCard = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${config.api.cards}/${id}/freeze`, { data: { security } });
};

export const unfreezeCard = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${config.api.cards}/${id}/unfreeze`, { data: { security } });
};

export const terminateCard = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${config.api.cards}/${id}/terminate`, { data: { security } });
};

export const getCardTransactions = async (data: {
  page?: number;
  size?: number;
  cardId?: string;
  currency?: string;
  type?: CardTransactionType;
  status?: CardTransactionStatus;
}): Promise<CardTransactionListDto> => {
  const { page = 1, size = 20, cardId, currency, type, status } = data;
  return await Request.getWithUserToken<CardTransactionListDto>(
    `${config.api.cards}/transactions${getQueryParams({
      page,
      size,
      cardId,
      currency,
      type,
      status,
    })}`,
  );
};

export const getSupportedCurrencies = async (): Promise<CardSupportedCurrencyListDto> => {
  return await Request.getWithUserToken<CardSupportedCurrencyListDto>(`${config.api.cards}/supported/currencies`);
};

export const getExchangeRate = async (fromCurrency: string, toCurrency: string) => {
  return await Request.getWithUserToken<ExchangeRate>(`${config.api.cards}/rates${getQueryParams({ fromCurrency, toCurrency })}`);
};
